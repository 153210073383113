import { Component } from "react";
import "./css/privacypolicy.css";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaFile,FaChrome,FaYoutube } from "react-icons/fa";

class PrivacyPolicy extends Component {
	state = {}
	render() {
		return (
			<div>
				<nav className="navbar navbar-expand-lg bg-primary fixed-top" id="mainNav">
					<div className="container">
						<div>
							<img className="notionnoteslogo" src="assets/NotionNotes.png" title="Notion Notes" alt="Notion Notes" />
							<Link to="/" className="navbar-brand" style={{"text-decoration":"none"}}>&nbsp; Notion Notes</Link>
						</div>

					</div>
				</nav>
				<div className="privacybody">
					<article id="81ebdd8e-a27f-4a71-bda0-2153a56c8a66" className="page sans">
						<header>
							<div className="page-header-icon undefined"><img className="icon"
								src="assets/NotionNotes.png" /></div>
							<h1 className="page-title">Privacy Policy</h1>
						</header>
						<div className="page-body">
							<h1 id="f548611f-f8bd-4d41-aa12-b63a08b2cda2" className="">Privacy Policy</h1>
							<p id="ac448259-790d-449a-a60b-6bb2f3957576" className="">Last updated: July 15, 2022</p>
							<p id="a2380bc6-1b26-451d-b317-4577b8232cbe" className="">This Privacy Policy describes how www.notionNotes.com
								(the “Site” or “we”) collects, uses, and discloses your Personal Information when you use our extension
								and visit our website.</p>
							<p id="d49eb7ad-77db-41e5-aba1-b8930a0ae1e7" className="">By using the Service, You agree to the collection and
								use of information in accordance with this Privacy Policy.</p>
							<p id="74d3ab84-b0fe-43eb-9295-ae9b56cf17c2" className="">&quot;The use of information received from Google APIs
								will adhere to the <a
									href="https://developer.chrome.com/docs/webstore/program_policies/#userdata">Chrome Web Store User
									Data Policy</a>, including the <a
										href="https://www.notion.so/Privacy-Policy-81ebdd8ea27f4a71bda02153a56c8a66">Limited Use</a>
								requirements.&quot;</p>
							<p id="4aa54a28-9d80-4e5c-bdec-4c6a1525a65b" className="">Limited Use:</p>
							<ul id="7abac1b1-6e13-416f-8adb-9b7217a31463" className="bulleted-list">
								<li style={{ "list-style-type": "disc" }}>We do not transfer and save any user specific data.</li>
							</ul>
							<ul id="fee24131-b800-4a04-85d0-72d574ab79bb" className="bulleted-list">
								<li style={{ "list-style-type": "disc" }}>No human can read or access the user data.</li>
							</ul>
							<ul id="779eb9b5-db18-497d-8916-c8e56622d779" className="bulleted-list">
								<li style={{ "list-style-type": "disc" }}>Our Notion integration(NotionNotes) has ability to read user
									information including email address.</li>
							</ul>
							<h1 id="4087471d-ada0-420c-88cb-85af420a2975" className="">Web Browsing Activity</h1>
							<p id="846dacc2-c70b-476d-bb9d-af13739eeff8" className="">Extension has the ability to grab the page URL and
								selected text using context menu in order to save it on users notion workspace.This is the prime
								functionality implemented for users to store selected text and corresponding URL on Notion.</p>
							<p id="ae640268-0813-4c03-8c5d-e4c0dcc540e5" className="">
							</p>
							<h1 id="cbfe8c7f-c474-4a0e-b08d-ff5735ccbc6f" className="">Personal Data</h1>
							<p id="4111e9c6-3ef3-4473-bc74-ec90a7c9fff2" className="">We do not collect or store any user data. Our Notion
								integration(NotionNotes) has ability to read user information including email address. You are in charge
								for access, you can decide which pages of your notion workspace can be accessed by our notion
								integration.</p>
							<h1 id="c1d8edca-a91a-4e6e-853c-551a8362efad" className="">Children&#x27;s Privacy</h1>
							<p id="390e4404-c1b5-4a0d-8f46-b033fde62290" className="">Our Service does not address anyone under the age of
								13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If
								You are a parent or guardian and You are aware that Your child has provided Us with Personal Data,
								please contact Us. If We become aware that We have collected Personal Data from anyone under the age of
								13 without verification of parental consent, We take steps to remove that information from Our servers.
							</p>
							<p id="4c97b20c-a9ab-40b0-a557-9c2a5e673a1d" className="">If We need to rely on consent as a legal basis for
								processing Your information and Your country requires consent from a parent, We may require Your
								parent&#x27;s consent before We collect and use that information.</p>
							<h1 id="29176170-cfcd-4d89-b212-960ae76c4a90" className="">Local Storage</h1>
							<p id="659f1e27-c72e-4784-9e72-e2f352513d73" className="">We use browser&#x27;s localStorage to store some of
								token data to access user&#x27;s pages for notion, you may erase the storage by deleting your
								browser&#x27;s history</p>
							<h1 id="fab479fd-c002-4e67-ad6a-63edc6f866ac" className="">Links to Other Websites</h1>
							<p id="6eeb1bb8-a5c1-4f2c-a343-7b526f5345b3" className="">Our Service may contain links to other websites that
								are not operated by Us. If You click on a third party link, You will be directed to that third
								party&#x27;s site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
							<p id="8cdf44d0-5735-4a7a-a1e5-8e81980abf7b" className="">We have no control over and assume no responsibility
								for the content, privacy policies or practices of any third party sites or services.</p>
							<h1 id="be18bb00-cf13-40e4-b6f3-74cdcfd2c60e" className="">Changes to this Privacy Policy</h1>
							<p id="b5ef23ba-0c4a-4217-af27-c2266f0ae5bb" className="">We may update Our Privacy Policy from time to time. We
								will notify You of any changes by posting the new Privacy Policy on this page.</p>
							<p id="7b8161e7-9623-4fcd-a885-584f983e1bca" className="">We will let You know via email and/or a prominent
								notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot;
								date at the top of this Privacy Policy.</p>
							<p id="2af921bb-c5a2-4c7f-ba44-2e865923bb05" className="">You are advised to review this Privacy Policy
								periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this
								page.</p>
							<p id="6c29e052-b649-4c36-8a02-fa475b4f07c2" className="">
							</p>
							<h1 id="dd6fb46e-6888-4b7f-a05e-d22408f6b14f" className="">Advertisement</h1>
							<p id="c20d82c1-34ce-47f1-a97f-c24b5d686a90" className="">As of now we do not show any advertisement on our
								extension.</p>
							<h1 id="788c9034-76b1-4d1a-8a17-fc55cfa35673" className="">Contact Us</h1>
							<p id="bccd5377-f970-456f-b08e-8402a0a3e262" className="">If you have any questions about this Privacy Policy,
								You can contact us:</p>
							<ul id="7ff91a11-06ec-412c-adec-b019c4fafc94" className="bulleted-list">
								<li style={{ "list-style-type": "disc" }}>By email: amitwpu@gmail.com</li>
							</ul>
							<ul id="a3b9f091-72f7-414e-a56e-7cd12747fe02" className="bulleted-list">
								<li style={{ "list-style-type": "disc" }}>By visiting this page on our website: <a
									href="https://www.notion.so/Contact-US-7c8749725fc64455b440c762a23acb30">https://tulip-swoop-fe5.notion.site/Contact-US-7c8749725fc64455b440c762a23acb30</a>
								</li>
							</ul>
							<p id="6dfb230f-c145-4d93-bcf0-17aa32efac39" className="">
							</p>
						</div>
					</article>
				</div>
				<footer className="footer text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <h6 className="mb-4">Connect with us on</h6>
                                <a className="btn btn-outline-light btn-social mx-1" href="https://twitter.com/Notion_Notes"><FaTwitter /></a>
                            </div>
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <h6 className="mb-4">Download Extension</h6>
                                <a className="btn btn-outline-light btn-social mx-1" href="https://chrome.google.com/webstore/detail/notion-notes/ilehjnkhpecbpcpknpdghocgkdiaakmh"><FaChrome /></a>
                            </div>
							<div className="col-lg-4 mb-5 mb-lg-0">
                                <h6 className="mb-4">Youtube Channel</h6>
                                <a className="btn btn-outline-light btn-social mx-1" href="https://www.youtube.com/channel/UCDVqTLzTSxRtdg-VZSBGchw"><FaYoutube /></a>
                            </div>
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <h6 className="mb-4">View Documentation</h6>
                                <Link className="btn btn-outline-light btn-social mx-1"
                                    to="/documentation"><FaFile /></Link>
                            </div>
                        </div>
                    </div>
                </footer>

				<div className="copyright py-4 text-center text-white">
					<div className="container"><small>Copyright &copy; Notion Notes</small></div>

					<Link to="/privacypolicy">Privacy Policies</Link>
				</div>
			</div>
		);
	}
}
export default PrivacyPolicy;