
import { FaSpinner } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../pages/css/pagenotfound.css"
export default function PageNotFound() {
    return (
        <div className="mainbox">
            <div className="error1">
                <div className="err">4</div>
                <FaSpinner className="far fa-question-circle fa-spin" />
                <div className="err2">4</div>
            </div>
            <div className="msg">This page never existed! <p>Let's go <Link to="/">Home</Link> and try from there.</p></div>
        </div>
    );
}