import { Component } from "react";
import "./css/privacypolicy.css"
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaFile } from "react-icons/fa";

class Documentation extends Component {
	state = {}
	render() {
		return (
			<div>
				<nav className="navbar navbar-expand-lg bg-primary fixed-top" id="mainNav">
					<div className="container">
						<div>
							<img className="notionnoteslogo" src="assets/NotionNotes.png" title="Notion Notes" alt="Notion Notes" />
							<Link to="/" className="navbar-brand" style={{ "text-decoration": "none" }}>&nbsp; Notion Notes</Link>
						</div>

					</div>
				</nav>
				<div className="privacybody">
					<article id="a74b91ab-bda1-4e28-999f-6447dabc66a9" className="page sans">
						<header>
							<div className="page-header-icon undefined"><img className="icon"
								src="./assets/ss//NotionNotes.png" /></div>
							<h1 className="page-title">Documentation</h1>
						</header>
						<div className="page-body">
							<h2 id="66df5993-0ef2-4e5b-a264-e65fa9c196ed" className="">
								<summary>How to use</summary>

							</h2>
							<div className="indented">
								<h3 id="d3891185-8bca-49e2-9d42-f255f0b72929" className="">
									<details open="">
										<summary>Install extension</summary>
										<figure id="" className="indented">
											<ol type="1" id="cce71493-cc60-424e-86fb-7df73baaf1e7" className="numbered-list" start="1">
												<li>Go to chrome webstore to install the extension - <a href='https://chrome.google.com/webstore/detail/notion-notes/ilehjnkhpecbpcpknpdghocgkdiaakmh' className="highlight-blue">Notion Notes</a> </li>
											</ol></figure>
									</details>
								</h3>

								<h3 id="509a40ec-b795-488a-832d-bb0f0a17299d" className="toggle">
									<details open="">
										<summary>Use extension</summary>
										<figure id="b742f467-7ac1-47e2-ae83-a24391f13429" className="indented">
											<ol type="1" id="a658c32d-16d1-4a8a-bc08-95f5eb986824" className="numbered-list" start="1">
												<li>In order to use this extension, open the extension and click on Sign in.</li>
											</ol>
											<figure id="b742f467-7ac1-47e2-ae83-a24391f13429" className="image"><a
												href="./assets/ss/signin.png"><img style={{ "width": "192px" }}
													src="./assets/ss/signin.png" /></a></figure>
											<p id="385d59eb-e639-4776-af46-19a85285386e" className="">
											</p>
											<ol type="1" id="cf1dd1eb-23ca-493f-8f00-18e9cb2c0140" className="numbered-list" start="2">
												<li>It will open a new tab where you will have to authorize NotionNotes with your notion
													account.</li>
											</ol>
											<ol type="1" id="fcf27125-1553-4b05-8f4e-7523aaef54aa" className="numbered-list" start="3">
												<li>Give permission of pages where you would like to save the the highlights.</li>
											</ol>
											<figure id="852c1230-d10b-4811-85c8-6a9c432b30b6" className="image"><a
												href="./assets/ss/imgonline-com-ua-twotoone-wIjnaVL0KhG.jpg"><img
													style={{ "width": "1065px" }}
													src="./assets/ss/imgonline-com-ua-twotoone-wIjnaVL0KhG.jpg" /></a>
											</figure>
											<p id="67584775-1381-451b-a0da-cdf8f33f0839" className="">
											</p>
											<ol type="1" id="1fa6878c-6361-4924-b024-92802ecf25d6" className="numbered-list" start="4">
												<li>We automatically select a default page for you, the notes/images you select will go to default selected page, you can check the page by opening the extension.</li>
											</ol>

											<ol type="1" id="fb1ae722-f72f-48c8-b983-1b58442ee999" className="numbered-list" start="5">
												<li>Highlight any content from browser and on right click select save to NotionNotes.</li>
											</ol>
											<figure id="68970ba6-97e6-439f-a2a8-e3103b8167cc" className="image"><a
												href="./assets/ss/contextmenu.png"><img
													style={{ "width": "619px" }}
													src="./assets/ss/contextmenu.png" /></a></figure>
											<p id="0e4d2431-bf37-4b26-9546-b4bbd4f13ca8" className="">
											</p>
											<ol type="1" id="fb1ae722-f72f-48c8-b983-1b58442ee999" className="numbered-list" start="5">
												<li>Right click on any image from browser and click Save to NotionNotes.</li>
											</ol>
											<figure id="68970ba6-97e6-439f-a2a8-e3103b8167cc" className="image"><a
												href="./assets/ss/saveImageToNotionNotes.png"><img
													style={{ "width": "619px" }}
													src="./assets/ss/saveImageToNotionNotes.png" /></a></figure>
											<p id="" className="">
											</p>
											<ol type="1" id="c908cd47-c4f2-4c22-b045-2da1fa2ca698" className="numbered-list" start="6">
												<li>Your highlights and Images are saved on your selected page.</li>
											</ol>
											<ol type="1" id="2dd5b0a6-6539-4e4c-95b8-81fa0c09cf55" className="numbered-list" start="7">
												<li>In order to see your highlights open extension and click on <mark
													className="highlight-blue"><strong>1. Visit Notes</strong></mark> you will be redirected to
													your notes.
													<mark className="highlight-blue"><strong>2. Visit Saved Images</strong></mark> you will be redirected to
													your SavedImages page.
												</li>
											</ol>
											<figure id="6ca55e90-84cc-4d3a-8a13-a69d8ad6390c" className="image"><a
												href="./assets/ss/visitnotes.png"><img
													style={{ "width": "240px" }}
													src="./assets/ss/visitnotes.png" /></a></figure>
											<p id="e2512ec0-8ab4-4af5-864f-6d6a7305f785" className="">
											</p></figure>
									</details>
								</h3>

								<h3 id="7c636b80-959f-49bd-a125-1746bceb0325" className="toggle">
									<details open="">
										<summary>Add new columns and use filter/sort/export</summary>
										<figure id="" className="indented">
											<ul id="de480500-a6b8-4ea1-8f5c-6c2bb6869c93" className="toggle">
												<li>
													<details open="">
														<summary>Click on the ‘<mark className="highlight-blue"><strong>+’</strong></mark> button
															shown in the image and add a new property in you default view.</summary>
														<figure id="5be7db32-2868-4f70-b550-0a88e0aae029" className="image"><a
															href="./assets/ss/plus.png"><img
																style={{ "width": "576px" }}
																src="./assets/ss/plus.png" /></a>
														</figure>
													</details>
												</li>
											</ul>
											<ul id="4ba44b52-c4fd-4e3f-9a06-45e2420348cd" className="toggle">
												<li>
													<details open="">
														<summary>Add the property and select property type as <mark
															className="highlight-blue"><strong>Created time</strong></mark></summary>
														<figure id="12eb0633-df30-4c34-b428-1739ddc78ec5" className="image"><a
															href="./assets/ss/createdat.png"><img
																style={{ "width": "384px" }}
																src="./assets/ss/createdat.png" /></a>
														</figure>
													</details>
												</li>
											</ul>
											<ul id="6485aab7-6211-481e-b795-45d68eca5341" className="toggle">
												<li>
													<details open="">
														<summary>For filter, sort and export use the option provided on top right corner of your
															page.</summary>
														<figure id="6d3e8dbd-9f56-47d7-8af4-05bddc3fa76c" className="image"><a
															href="./assets/ss/sortbycreatedat.png"><img
																style={{ "width": "576px" }}
																src="./assets/ss/sortbycreatedat.png" /></a>
														</figure>
													</details>
												</li>
											</ul>
										</figure>
									</details>
								</h3>

							</div>
							<h2 id="d4e36689-7d7f-400b-af22-ae8cef3689a2" className="">
								<summary>FAQs</summary>
							</h2>
							<div className="indented">
								<h3 id="e99f4d30-8f87-4b0c-bb8a-ec4d7383db0b" className="toggle">
									<details open="">
										<summary>Unable to see pages in my extension?</summary>
										<figure id="43b6ee90-e615-43df-a4ee-edf140abb590" className="indented">
											<p id="d2b38391-f22e-40ed-a6da-97111f648098" className="">If you are unable to see pages in your
												extension that means you have not given permission for accessing pages to extension. To fix
												this, logout and Sign in again, this time do provide the permission and select some of the pages
												from your workspace.</p>
											<figure id="43b6ee90-e615-43df-a4ee-edf140abb590" className="image"><a
												href="./assets/ss/pagesaccess.png"><img
													style={{ "width": "336px" }}
													src="./assets/ss/pagesaccess.png" /></a></figure></figure>
									</details>
								</h3>

								<h3 id="6ac36143-8046-41b5-9689-5de3979e2add" className="">
									<details open="">
										<summary><mark className="highlight-blue">Save to NotionNotes</mark> button not showing on right
											click?</summary>
										<figure id="91d0ecb0-37ff-463d-91ab-f3d475df435b" className="indented">
											<p id="52860ad1-da93-4fec-8d35-6244921afaa2" className="">If you are unable to see Save to NotionNotes
												button, click on the NotionNotes extension and check again or reselect a page and try</p>
											<figure id="91d0ecb0-37ff-463d-91ab-f3d475df435b" className="image"><a
												href="./assets/ss/pagesdisplay%201.png"><img
													style={{ "width": "240px" }}
													src="./assets/ss/pagesdisplay%201.png" /></a>
											</figure>
										</figure>
									</details>
								</h3>

								<h3 id="48a75f23-0707-4ace-994e-3a43a9206986" className="">
									<details open="">
										<summary>Unable to save highlights?</summary>
										<div className="indented">
											<p id="178adb07-9431-464f-9c9b-79021b64fcbc" className="">Check if your have changed name or property of
												columns “<strong>Notes</strong>” and “<strong>Page URL”</strong>, if you have, please revert
												those changes back and check your browser notification for particular error. <mark
													className="highlight-blue"><strong><a
														href="https://www.notion.so/Contact-US-7c8749725fc64455b440c762a23acb30">Contact
														us</a></strong></mark><a
															href="https://www.notion.so/Contact-US-7c8749725fc64455b440c762a23acb30"> </a>if your are
												unable to resolve your query.</p>
											<p id="4e0cf150-b019-4a74-b663-069dde4ecf36" className="">You may add new properties and modify that,
												but do not modify “<strong>Notes</strong>” and “<strong>Page URL” </strong>columns.</p>
										</div>
									</details>
								</h3>

								<h3 id="2d59fc2b-6566-454a-bbf6-799dd9dc7ebc" className="">
									<details open="">
										<summary>Unable to see all the pages to which the access was given during authorization?
										</summary>
										<div className="indented">
											<p id="4211726e-022d-4804-908d-cd4d5e0f740a" className="">There are two things in notion - pages and
												databases, for now we only store in the pages, so even if you have provided access to the
												databases, those will not be shown as we are only showing the pages.</p>
											<p id="99b9ce19-d44b-41a5-a46a-cfff16afdf94" className="">Even after selecting a page you are not able
												to see that in extension please logout and sign in again or contact us</p>
											<p id="65d4f6d8-0ffe-40b7-987f-effb4e2c0a49" className="">
											</p>
										</div>
									</details>
								</h3>
								<h3 id="e99f4d30-8f87-4b0c-bb8a-ec4d7383db0b" className="toggle">
									<details open="">
										<summary>How to organize the Saved Images DB in Notion.</summary>
										<figure id="43b6ee90-e615-43df-a4ee-edf140abb590" className="indented">
											<a href="https://www.youtube.com/watch?v=xCKmvkXW13E" style={{ "color": "black" }}>Checkout this video- https://www.youtube.com/watch?v=xCKmvkXW13E </a>
										</figure>
									</details>
								</h3>

								<h3 id="e99f4d30-8f87-4b0c-bb8a-ec4d7383db0b" className="toggle">
									<details open="">
										<summary>How to filter and sort</summary>
										<figure id="43b6ee90-e615-43df-a4ee-edf140abb590" className="indented">
											<a href="https://www.youtube.com/watch?v=nL7dcExTgLA" style={{ "color": "black" }}>Checkout this video- https://www.youtube.com/watch?v=nL7dcExTgLA</a>
										</figure>
									</details>
								</h3>
							</div>
							<p id="de14bef7-7909-4985-8b7d-40e58b1ee596" className="">
							</p>
							<p id="7d2b74a3-ebef-4d0c-a6a4-738c9e75e263" className="">For any queries <a
								href="https://www.notion.so/Contact-US-7c8749725fc64455b440c762a23acb30"><mark
									className="highlight-blue"><strong>Contact Us</strong></mark></a>.</p>
							<p id="41b65205-656d-480d-b8ba-26b5900995fe" className="">
							</p>
						</div>
					</article>
				</div>
				<div className="copyright py-4 text-center text-white">
					<div className="container"><small>Copyright &copy; Notion Notes</small></div>

					<Link to="/privacypolicy">Privacy Policies</Link>
				</div>
			</div>
		);
	}
}

export default Documentation;
