import { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import "../js/scripts";
import Slider from "react-slick";
import AOS from "aos";
import "@fortawesome/fontawesome-svg-core"
import { FaChrome, FaDochub, FaFile, FaYoutube } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";


class Home extends Component {

    componentDidMount() {
        var navbarShrink = function () {
            const navbarCollapsible = document.body.querySelector('#mainNav');
            if (!navbarCollapsible) {
                return;
            }
            if (window.scrollY === 0) {
                navbarCollapsible.classList.remove('navbar-shrink')
            } else {
                navbarCollapsible.classList.add('navbar-shrink')
            }

        };
        navbarShrink();
        document.addEventListener('scroll', navbarShrink);
        const navbarToggler = document.body.querySelector('.navbar-toggler');
        const responsiveNavItems = [].slice.call(
            document.querySelectorAll('#navbarResponsive .nav-link')
        );
        var navRes = document.getElementById('navbarResponsive')

        var navResponse = document.getElementById('menu_responsive_id')

        navResponse.onclick = function () {
            navRes.classList.contains('show') ? navRes.classList.remove('show') : navRes.classList.add('show');
        }
        responsiveNavItems.map(function (responsiveNavItem) {
            responsiveNavItem.addEventListener('click', () => {
                if (window.getComputedStyle(navbarToggler).display !== 'none') {
                    navbarToggler.click();
                }
            });
        });
        AOS.init({
            duration: 2000
        });
    }
    state = {}
    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg bg-primary fixed-top" id="mainNav">
                    <div className="container">
                        <div>
                            <img className="notionnoteslogo" src="assets/NotionNotes.png" title="Notion Notes" alt="Notion Notes" />
                            <a className="navbar-brand" href="#page-top" style={{ "text-decoration": "none","font-weight": "500" }}>&nbsp; Notion Notes</a>
                        </div>
                        <button className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"
                            data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
                            aria-expanded="false" aria-label="Toggle navigation" id="menu_responsive_id">
                            Menu
                            <i className="fas fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item mx-0 mx-lg-1" style={{ "list-style-type": "none" }}><a className="nav-link py-3 px-0 px-lg-3 rounded"
                                    href="#features">Features</a></li>

                                <li className="nav-item mx-0 mx-lg-1" style={{ "list-style-type": "none" }}><Link to="/documentation" className="nav-link py-3 px-0 px-lg-3 rounded">Documentation</Link></li>


                                <li className="nav-item mx-0 mx-lg-1" style={{ "list-style-type": "none" }}><a className="nav-link py-3 px-0 px-lg-3 rounded"
                                    href="#contact">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <header className="top_header bg-secondary text-black text-center" id="page-top">
                    <div className="container d-flex flex-column" style={{ "margin": "auto 50px", "padding-right": "130px", "background-image": "linear-gradient(to right,#e8edef, white)" }}>

                        <h1 className="masthead-heading" style={{ "textAlign": "left", "lineHeight": "5rem", "marginTop": "0" }}>Notion Notes - You click, we Save!</h1>

                        <h2 className="masthead-subheading mb-0" style={{ "textAlign": "left", "fontWeight": "450", "fontSize": "1.2rem" }}>Notion Notes is designed to save Highlights and Images in hassle-free manner, allowing you to focus on content-gathering without any Notion setup. We handle everthing for you!</h2>
                        <a className="button-81" style={{ "width": "fit-content", "textAlign": "left", "margin-left": "0" }}
                            href="https://chrome.google.com/webstore/detail/notion-notes/ilehjnkhpecbpcpknpdghocgkdiaakmh">
                            <i><FaChrome /></i>&nbsp;&nbsp;Install the Extension
                        </a>
                        <div style={{ "display": "flex" }}>
                            <img src="./assets/icons/Stars.png" style={{ "height": "45px", "margin-right": "15px" }} />
                            <p style={{ "fontSize": "1.0rem", "marginTop": "0.8rem" }}>Trusted by 100+ users!</p>
                        </div>

                    </div>
                    <div class="cards" style={{ "marginRight": "50px", "width": "auto" }}>
                        <article class="card" >
                            <header >
                                <h2 style={{
                                    "background": "#4398E1",
                                    "color": "white",
                                    "marginTop": "0.2rem",
                                    "padding": "5px"
                                }}>Try me Out!</h2>
                            </header>
                            <div style={{
                                "display": "flex",
                                "justify-content": "space-between",
                                "margin": "10px"
                            }} >
                                <h4 style={{ "marginTop": "0.15rem", "fontSize": "1.6rem", "fontWeight": "550" }}>Save this Text</h4>
                                <img src="assets/img/savethisimage.png" alt="Save this image" style={{ "height": "2.5rem" }} />
                            </div>

                            <div class="content">
                                <p>1. Select Text or Image from above</p>
                                <p>2. Right Click - Save to NotionNotes</p>
                                <p>3. Open the extension if installed</p>
                                <p>4. Click <strong>VisitNotes</strong> -&gt; View your saved Notes on Notion</p>
                                <p>5. Click <strong>VisitSavedImages</strong> -&gt; View your saved Images on Notion</p>
                            </div>
                            {/* <footer>I have a footer!</footer> */}
                        </article>
                    </div>
                    {/* <div>
                        <div className="container">
                            <div className="row"> */}
                    {/* <div className="col-lg-2 mb-5 mb-lg-0">
                        <div data-aos="fade-left" data-aos-duration="1000">
                            <img height="100%" width="100%" src="assets/ss/You_click_we_Save_largeCover.png" title="Context menu NOTIONNOTES"
                                alt="Context menu NOTIONNOTES" />
                        </div>
                    </div> */}
                    {/* <div className="col-lg-2" style={{ 'margin': 'auto',"list-style-type": "none"}}>
                                    <a className="button-81" 
                                        href="https://chrome.google.com/webstore/detail/notion-notes/ilehjnkhpecbpcpknpdghocgkdiaakmh">
                                        <i><FaChrome/></i>&nbsp;&nbsp;Go to Chrome Webstore
                                    </a>
                                </div> */}
                    {/* </div>
                        </div>
                    </div> */}
                </header>


                <div className="video_notionnotes">
                    <video width="80%" height="80%" controls muted autoPlay loop>
                        <source src="./assets/ss/FinalDemoVideo.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>


                <hr></hr>
                <div className="jumbotron jumbotron-fluid feature" style={{ "marginLeft": "50px", "marginRight": "50px" }} id="features">
                    <div className="container my-5">
                        <div className="row justify-content-between text-center text-md-left">
                            <div data-aos="fade-right" data-aos-duration="1000" className="col-md-6" style={{ 'margin': 'auto' }}>
                                <h2 className="font-weight-bold">Single click - Saved to Notion</h2>
                                <p className="my-4">

                                </p>
                                <strong >Traditional way </strong>: Select Text -&gt; Copy selected text-&gt; paste to notepad/notion -&gt; Back to webpage
                                <p className="my-4" > <strong >Using NotionNotes</strong> : Select text -&gt; right click -&gt; save to notionnotes
                                </p>
                            </div>
                            <div data-aos="fade-left" data-aos-duration="1000" className="col-md-6 align-self-center ">
                                <img height="100%" width="100%" src="assets/ss/contextmenu.png" title="Context menu NOTIONNOTES" class="image_border"
                                    alt="Context menu NOTIONNOTES" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="jumbotron jumbotron-fluid feature" style={{ "marginLeft": "50px", "marginRight": "50px" }} id="feature-last">
                    <div className="container" >
                        <div className="row justify-content-between text-center text-md-left inverse_column">
                            <div data-aos="fade-right" data-aos-duration="1000" className="col-md-6 align-self-center flex-md-first ">
                                <img height="45%" width="45%" src="./assets/ss/pagesdisplay.png" title="Display all pages" class="image_border"
                                    alt="All pages" />
                            </div>
                            <div data-aos="fade-left" data-aos-duration="1000" className="col-md-6 flex-md-last" style={{ "margin": "auto" }}>
                                <h2 className="font-weight-bold">Select any page from your Workspace</h2>
                                <p className="my-4">
                                    You might want to save different highlights to different locations, we got you covered! Select any page from your Workspace.
                                </p>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="jumbotron jumbotron-fluid feature" id="feature-first">
                            <div className="container my-5" >
                                <div className="row justify-content-between text-center text-md-left">
                                    <div data-aos="fade-right" data-aos-duration="1000" className="col-md-6" style={{ "margin": "auto" }}>
                                        <h2 className="font-weight-bold">Visit your saved Highlights and Images</h2>
                                        <p className="my-4">No need to search your highlights on notion. Visit the highlights form the extension for selected page </p>
                                        <p className="my-4">We save Notes and Images in different DB for better visualization of notes</p>

                                    </div>
                                    <div data-aos="fade-left" data-aos-duration="1000" className="col-md-6 align-self-center">
                                        <img height="45%" width="45%" src="assets/ss/visitnotes.png" title="Filter and sort" class="image_border"
                                            alt="Filter and sort" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <section className="page-section text-white mb-0" id="about" style={{ "padding": "0 rem" }}>
                            {/* <div className="jumbotron jumbotron-fluid feature" id="feature-first"> */}
                            <div data-aos="fade-up" className="container my-5 mobile_padding">


                                <div>
                                    <h4 className="testimonials_header">Users are loving us!</h4>
                                    <div className="col-md-6 align-self-center" style={{ "width": "90%", "margin": "auto" }} id="slick_image_slider">
                                        <Slider dots={true} infinite={true} slidesToShow={1}
                                            adaptiveHeight={true}
                                            autoplay={true}
                                            autoplaySpeed={3000}>
                                            <img height="70%" width="70%" src="assets/ss/review1.png"
                                                title="User Review" alt="User Review" />
                                            <img height="70%" width="70%" src="assets/ss/review2.png"
                                                title="User Review" alt="User Review" />
                                            <img height="70%" width="70%" src="assets/ss/review3.png" title="User Review"
                                                alt="User Review" />
                                            <img height="70%" width="70%" src="assets/ss/review4.png"
                                                title="User Review" alt="User Review" />
                                            <img height="70%" width="70%" src="assets/ss/review5.png"
                                                title="Filter and sort by Notes" alt="Filter and sort by Notes" />
                                        </Slider>
                                    </div>
                                </div>

                            </div>
                            {/* </div> */}
                        </section>
                        <section className="page-section" id="contact" style={{ "paddingTop": "1rem", "paddingBottom": "2rem" }}>
                            <div className="container">
                                <h3 style={{ "textAlign": "center" }}>Join hundreds of users who are hacking their productivity</h3>
                                <h3 style={{ "textAlign": "center", "fontStyle": "italic", "fontWeight": "400" }}>"Great things are done by a series of small things bought together" - Vincent Van Gogh </h3>
                                <div style={{ "textAlign": "center" }}>
                                    <a className="button-81" style={{ "width": "fit-content" }}
                                        href="https://chrome.google.com/webstore/detail/notion-notes/ilehjnkhpecbpcpknpdghocgkdiaakmh">
                                        <i><FaChrome /></i>&nbsp;&nbsp;Go to Chrome Webstore
                                    </a>
                                </div>

                            </div>
                        </section>
                        <section className="text_with_icon">
                            <div class="row">
                                <div class="column">
                                    <div class="container-textwithicon" data-aos="fade-up" data-aos-duration="1000">
                                        <img src="assets/icons/privacy_icon.png" alt="Focus on content gathering" />

                                        <h4 class="h5">Confidentiality</h4>
                                        <h5 class="h4">Your saved content is yours only, no one else can view it, Not even us!</h5>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="container-textwithicon" data-aos="fade-up" data-aos-duration="1600">
                                        <img src="assets/icons/unlimited_saving.png" alt="" />

                                        <h4 class="h5">Free and Unlimited</h4>
                                        <h5 class="h4">Enjoy lifetime free and infinite storage provided by notion</h5>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="container-textwithicon" data-aos="fade-up" data-aos-duration="2200">
                                        <img src="assets/icons/focus_contentgathering.png" alt="" />

                                        <h4 class="h5">Focus on content gathering</h4>
                                        <h5 class="h4">You concentrate on research, we got you covered for saving important content!</h5>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <section
                    className="page-section text-white mb-0"
                    style={{ padding: "0 rem" }}
                >
                    <div className="output_notesimages">
                        <div class="image_border" style={{ margin: "25px", height: "100%" }}>
                            <h4 className="testimonials_header savedNotes_font" >Your Saved Notes</h4>
                            <img
                                height="100%"
                                src="assets/img/OUTPUT_notes.png"
                                title="User Review"
                                alt="User Review"
                            />
                        </div>

                        <div class="image_border" style={{ margin: "25px", height: "100%" }}>
                            <h4 className="testimonials_header savedNotes_font">Your Saved Images</h4>
                            <img
                                height="100%"
                                src="assets/img/OUTPUT_savedImages.png"
                                title="User Review"
                                alt="User Review"
                            />
                        </div>

                    </div>
                </section>

                <section className="page-section" id="contact" style={{ "paddingTop": "1rem" }}>
                    <div className="container">

                        <iframe style={{ "border": "none", "width": "100%" }} height="470px"
                            src="https://notionforms.io/forms/please-send-us-your-queries-1"></iframe>
                    </div>
                </section>

                <footer className="footer text-center">
                    <div className="container">
                        <div className="row row_socialmedia">
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <h6 className="mb-4">Connect with us on</h6>
                                <a className="btn btn-outline-light btn-social mx-1" href="https://twitter.com/Notion_Notes"><FaTwitter /></a>
                            </div>
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <h6 className="mb-4">Download Extension</h6>
                                <a className="btn btn-outline-light btn-social mx-1" href="https://chrome.google.com/webstore/detail/notion-notes/ilehjnkhpecbpcpknpdghocgkdiaakmh"><FaChrome /></a>
                            </div>
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <h6 className="mb-4">Youtube Channel</h6>
                                <a className="btn btn-outline-light btn-social mx-1" href="https://www.youtube.com/channel/UCDVqTLzTSxRtdg-VZSBGchw"><FaYoutube /></a>
                            </div>
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <h6 className="mb-4">View Documentation</h6>
                                <Link className="btn btn-outline-light btn-social mx-1"
                                    to="/documentation"><FaFile /></Link>
                            </div>
                        </div>
                    </div>
                </footer>

                <div className="copyright   text-white" style={{ "textAlign": "center" }}>
                    <div style={{ "marginRight": "10px" }}><small>Copyright &copy; Notion Notes</small></div>

                    <div><Link to="/privacypolicy">Privacy Policies</Link></div>
                </div>
            </div>
        );
    }
}
export default Home;